/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';

export default function Layout({ children, data, pageContext, ...rest }) {
  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <Container sx={{ marginBottom: 5 }}>{children}</Container>
    </Box>
  );
}
