/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Card from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Card/Card';
import Layout from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Layout/Layout';
import TextInput from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/TextInput/TextInput';
import useFirebase from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/useFirebase';
import useAuth from '@bottlebooks/gatsby-plugin-auth/src/useAuth';
import { Box, Button, Flex, Link, Text } from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { ErrorMessage as BaseErrorMessage, Field, Form, Formik } from 'formik';
import { jsx } from 'theme-ui';
// import { Trans } from '@lingui/macro';
import * as yup from 'yup';

const schema = yup.object().shape({
  code: yup
    .string()
    .required('Please enter a valid code.')
    .trim() // This is handy because when calling schema.cast() you can auto-trim the string.
    .notOneOf(
      ['6JXLY3'],
      '6JXLY3 is only an example. A valid code is required.'
    )
    // It also checks the min length after trimming.
    .min(4, 'A join code is 4 to 6 characters.')
    .max(6, 'A join code is 4 to 6 characters.'),
});

/**
 *
 * @param {object} props
 * @param {string} props.collectionId
 * @param {() => void} props.onSuccess
 * @param {string} [props.joinCode]
 * @returns
 */
export default function Join({ joinCode, collectionId, onSuccess }) {
  const { firebase, db } = useFirebase();
  const { signOut } = useAuth();
  const user = firebase?.auth()?.currentUser;
  console.log(user && 'Welcome ' + user.email + ' ' + user.uid);
  return (
    <Layout>
      <Box>
        <Flex
          justify="center"
          sx={{
            paddingTop: ['190px', null, '20vh'],
          }}
        >
          <Box as={Card} sx={{ maxWidth: '400px', padding: 3 }}>
            <Flex direction="column">
              <Formik
                initialValues={{
                  code: (joinCode && String(joinCode).toUpperCase()) || '',
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const { code = '' } = values;
                  const upperCase = {
                    ...values,
                    code: code.toUpperCase(),
                  };
                  onSubmit({
                    data: schema.cast(upperCase),
                    user,
                    db,
                    collectionId,
                    actions,
                    onSuccess,
                  });
                }}
                // FIXME This prevents the errors showing on modal display because an initial blur event happens on load.
                // TODO fix this differently.
                validateOnBlur={false}
              >
                {({ isSubmitting }) => (
                  <Form sx={{ textAlign: 'center' }}>
                    <Box sx={{ paddingTop: 2 }}>
                      <Box>
                        <Text sx={{ paddingBottom: 3 }}>
                          <Trans>
                            Enter your join code here to enter the event.
                          </Trans>
                        </Text>
                        <Box sx={{ marginY: 1 }}>
                          <ErrorMessage name="code" />
                          <ErrorMessage name="agreeTC" />
                        </Box>
                        <Box>
                          <Field
                            name="code"
                            placeholder="enter code here"
                            as={TextInput}
                            sx={{
                              '::-webkit-input-placeholder': {
                                color: '#AAAAAA',
                              },
                              textAlign: 'center',
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: '225px',
                            textAlign: 'center',
                            margin: '0 auto',
                            marginTop: 3,
                            marginBottom: 1,
                          }}
                        ></Box>
                      </Box>

                      <Button
                        sx={{ marginTop: 2 }}
                        type="submit"
                        variant="primary"
                      >
                        {isSubmitting ? (
                          <Trans>Submiting code…</Trans>
                        ) : (
                          // <Trans>Submit code</Trans>
                          <Trans>Enter now!</Trans>
                        )}
                      </Button>

                      <Flex direction="column" align="center">
                        <Text
                          sx={{
                            marginTop: 3,
                            maxWidth: '300px',
                            textAlign: 'center',
                          }}
                        >
                          <Trans>A join code looks like this: 6JXLY3</Trans>
                        </Text>
                        <Text
                          sx={{
                            marginTop: 3,
                            maxWidth: '300px',
                            textAlign: 'center',
                          }}
                        >
                          <Trans>
                            If you don't have a join code, ask your sommelier.
                          </Trans>
                        </Text>
                      </Flex>
                      <Text sx={{ marginTop: 3 }}>
                        <Trans>Already redeemed your join code?</Trans>
                        <br />
                        <Link onClick={signOut} sx={{ color: 'primary' }}>
                          <Trans>Sign into a different account</Trans>
                        </Link>
                      </Text>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
}

function ErrorMessage(props) {
  return (
    <BaseErrorMessage
      {...props}
      render={(message) => (
        <Box>
          <Text sx={{ color: 'red' }}>{message}</Text>
        </Box>
      )}
    />
  );
}

async function onSubmit({ data, user, db, collectionId, actions, onSuccess }) {
  const { setSubmitting, setErrors } = actions;

  const { isValidCode, isCurrentUserCode, error } = await checkUserCode({
    db,
    user,
    collectionId,
    code: data.code,
  });

  if (error) {
    let code =
      error === 'no_seats_available'
        ? 'There are no more seats available for this code. Contact welcomedesk@londonwinefair.com for help.'
        : `Sorry, '${data.code}' is not a valid code.`;
    return setErrors({ code });
    // return console.error('Missing arguments');
  }

  if (isValidCode) {
    if (isCurrentUserCode) {
      onSuccess && onSuccess();
      // navigate('/dashboard');
    } else {
      setErrors({
        code: `Sorry, '${data.code}' was already assigned to different user.`,
      });
    }
  } else {
    setErrors({
      code: `Sorry, '${data.code}' is not a valid code. Be sure that you use your code for the main event.`,
    });
  }
  setSubmitting(false);
}

async function checkUserCode({ db, user, collectionId, code }) {
  if (!db || !user || !code || !collectionId) {
    return { error: 'Missing arguments' };
  }
  code = String(code).toUpperCase().trim();
  let error,
    codeConfig = await getCodeConfiguration({ db, collectionId, code });
  let isCurrentUserCode = false;
  if (codeConfig?.seats) {
    ({ code: isCurrentUserCode, error } = await ensureUserCode({
      db,
      user,
      collectionId,
      code,
      seats: codeConfig?.seats,
    }));
  }

  return { isValidCode: isCurrentUserCode === code, isCurrentUserCode, error };

  /**
   * Get $joinCode configuration. Anyone can read joinCodes
   *
   * @param {object} options
   * @param {import('firebase').default.firestore.Firestore} options.db
   * @param {string} options.collectionId
   * @param {string} options.code
   * @returns {Promise<import('firebase').default.firestore.DocumentData | undefined>}
   */
  async function getCodeConfiguration({ db, collectionId, code }) {
    return new Promise((resolve, reject) => {
      try {
        let joinCodes = db
          .collection('events')
          .doc(collectionId)
          .collection('joinCodes');

        // for testing security rules
        // joinCodes.where('seat', '>=', '').get().then(snapshots =>{
        //   console.log('Join codes found: '+snapshots.size);
        // }).catch(err=>{
        //   console.error(err);
        // });
        joinCodes
          .doc(code)
          .get()
          .then((doc) => {
            if (doc.exists) {
              resolve(doc.data());
            } else {
              resolve({ seats: 0 });
            }
          })
          .catch((error) => {
            resolve({ seats: 0 });
            // reject(error);
          });
      } catch (e) {
        resolve({ seats: 0 });
        // reject(e);
      }
    });
  }
}

async function ensureUserCode({ db, user, collectionId, code, seats }) {
  const { uid: userId, email, displayName } = user;
  const users = db.collection('events').doc(collectionId).collection('users');
  // const pub_event_graph = db.collection('events').doc(collectionId).collection('pub_graph');

  return new Promise((resolve, reject) => {
    if (!seats) return {};
    // pub_event_graph
    //   .where('joinCode', '==', code)
    users
      .where('code', '==', code)
      .get()
      .then((querySnapshot) => {
        let seats_in_use = 0,
          codeAlreadyAssigned;
        querySnapshot.forEach((doc) => {
          const { userId: id } = doc.data();
          if (id !== userId) {
            // if current user has the code we should not count it in
            seats_in_use++;
          } else {
            codeAlreadyAssigned = true;
          }
        });

        if (codeAlreadyAssigned) {
          return resolve({ code });
        }
        if (seats_in_use < seats) {
          assignJoinCode({ userId, code }, (err, usedCode) => {
            resolve({ code: usedCode });
          });
        } else {
          // All seats are taken
          return resolve({ error: 'no_seats_available' });
        }
      })
      .catch((error) => {
        console.error(error);
        console.log('seats_check_failed');
        reject(new Error('seats_check_failed'));
      });
  });

  function assignJoinCode({ userId, code }, cb) {
    let userRef = users.doc(userId);
    // pub_event_graph.where()
    userRef
      .set({ userId, email, displayName }, { merge: true }) // we unser user object exists
      .then(() => {
        userRef
          .get()
          .then((doc) => {
            const { code: userAssignedCode } = doc.data();

            if (userAssignedCode) {
              // Join code is already assigned
              // We are going to used already assign code.
              // Todo we could try to assign new one and remove the old one?
              return cb(null, code);
              // else {
              //     // Trying to assing a new code
              //     reject(new Error('Different joinCode already assigned to the user'))
              //   }
              // resolve(true);
            } else {
              userRef
                .update({ code: code })
                .then(() => {
                  // console.log('Document successfully written!');
                  return cb(null, code);
                })
                .catch(cb);
            }
          })
          .catch(cb);
      })
      .catch(cb);
  }
}
