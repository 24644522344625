/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Flex, Text, P } from '@bottlebooks/gatsby-design-system';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { jsx } from 'theme-ui';
import Card from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Card/Card';
import RegistrationButton from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Join/RegistrationButton';
import Layout from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Layout/Layout';
import Logo from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/Logo';
import useFirebase from '@bottlebooks/gatsby-plugin-auth-lwf/src/components/useFirebase';
import { Trans } from '@lingui/macro';

export default function Login() {
  const { firebase } = useFirebase();
  if (!firebase?.auth) return null; // TODO This is only a quick fix - make this SSR-proof instead!
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (
    <Layout>
      <Flex
        justify="center"
        sx={{
          paddingTop: ['190px', null, '20vh'],
        }}
      >
        <Box
          as={Card}
          sx={{
            width: '387px',
            padding: 3,
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <P>
            <Trans>
              If this is your first time signing in, your account will
              automatically be created for you.
            </Trans>
          </P>
          <Box>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
}
