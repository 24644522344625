import {
  catalogs as tastingCatalogs,
  defaultLocale,
  ensureModule,
} from '@bottlebooks/gatsby-theme-event-tasting/src/wrapElement/lingui/i18n.config';
import { catalogs as eventCatalogs } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';
import de from '../../../../locales/de';
import en from '../../../../locales/en';
import es from '../../../../locales/es';
import fr from '../../../../locales/fr';
import it from '../../../../locales/it';
import pt from '../../../../locales/pt';

const localCatalogs = {
  de: ensureModule(de),
  en: ensureModule(en),
  es: ensureModule(es),
  fr: ensureModule(fr),
  it: ensureModule(it),
  pt: ensureModule(pt),
};

function mergeCatalogs(locale) {
  return {
    messages: {
      ...eventCatalogs[locale]?.messages,
      ...tastingCatalogs[locale]?.messages,
      ...localCatalogs[locale]?.messages,
    },
  };
}

const catalogs = {
  de: mergeCatalogs('de'),
  en: mergeCatalogs('en'),
  es: mergeCatalogs('es'),
  fr: mergeCatalogs('fr'),
  it: mergeCatalogs('it'),
  pt: mergeCatalogs('pt'),
};

export { locales } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
export { defaultLocale };

export function load(locale) {
  if (!locale) return {};
  const catalog = catalogs[locale];
  i18n.load(locale, catalog.messages);
  return catalog.messages;
}
